import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import CustomerStories from "../components/CustomerStories/CustomerStories";

import Favicon from "../images/Favicon.png";

import ogImage from "../images/Metadata/customer-stories.jpg"

const CustomerStoriesPage = () =>{
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Customer Stories - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="See how Drivosity transforms last mile delivery through real customer testimonials. From franchisees, operators, managers, and first-party delivery drivers, learn about the financial benefits, improved productivity, and safety enhancements from those who use our fleet management telematics solution daily. Explore now."/>

                <meta property="og:title" content="Customer Stories - Drivosity"/>
                <meta property="og:description" content="See how Drivosity transforms last mile delivery through real customer testimonials. From franchisees, operators, managers, and first-party delivery drivers, learn about the financial benefits, improved productivity, and safety enhancements from those who use our fleet management telematics solution daily. Explore now."/>
                <meta property="og:image" content={`${process.env.GATSBY_SITE_URL}${ogImage}`}/>
                <meta property="og:image:alt" content="A startup team in a modern office listens to a presentation, highlighting collaboration, innovation, and customer success stories."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`${process.env.GATSBY_SITE_URL}/customer-stories`}/>
            </Helmet>
            <Layout component={<CustomerStories/>}/>
        </>
    )
}

export default CustomerStoriesPage;
